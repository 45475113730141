<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $t("sig_verify.title_header") }}</h1>
      <div class="block">
        <b-field>
          <template #label>
            <slot name="label">Carica File</slot>
          </template>
          <b-upload v-model="file" drag-drop>
            <slot>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"> </b-icon>
                  </p>
                  <p>{{ $t("upload.form.file-button-label") }}</p>
                </div>
              </section>
            </slot>
          </b-upload>
        </b-field>
        <div class="tags">
          <span v-if="file" class="tag is-primary">
            {{ file.name }}
            <button
              class="delete is-small"
              type="button"
              @click="() => (file = null)"
            ></button>
          </span>
        </div>

        <b-button type="is-primary" @click="uploadFile" :disabled="!file">{{
          $t("buttons.upload")
        }}</b-button>
      </div>

      <div class="block">
        <b-progress
          :value="progressVal"
          show-value
          format="percent"
        ></b-progress>
      </div>

      <!-- <div v-for="(sig, index) in signatures" :key="index" class="block">
        <b-notification
          :type="getSigColor(sig.indication)"
          has-icon
          :closable="false"
        >
          <ul class="ml-5" style="list-style: disc">
            <li v-for="(val, name) in sig" :key="`${index}_${name}`">
              <span v-if="name !== 'certFile'">
                {{ `${name}: ${val}` }}
              </span>
              <span v-else>
                <b-button @click="downloadCertificate(val)" size="is-small">
                  Scarica certificato
                </b-button>
              </span>
            </li>
          </ul>
        </b-notification>
      </div> -->
      <validation-result
        v-if="validation"
        :data="validation"
      ></validation-result>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import FileSaver from "file-saver";
import { KUrls } from "@/helpers/constants";
import ValidationResult from "../components/ValidationResult.vue";
export default {
  components: { ValidationResult },
  data() {
    return {
      file: null,
      progressVal: 0,
      validation: null,
    };
  },
  computed: {
    // signatures() {
    //   if (this.validation) {
    //     return this.validation["simpleReport"]["signatureOrTimestamp"].map(
    //       (sig) => {
    //         const certId = sig.certificateChain.certificate.find(
    //           (c) => c.qualifiedName === sig["signedBy"]
    //         )["id"];
    //         console.log("certId", certId);
    //         const sigDiagnostic = this.validation["diagnosticData"][
    //           "signatures"
    //         ].find((s) => s.id === sig.id);
    //         console.log("sigDiagnostic", sigDiagnostic);
    //         const certDetail = sigDiagnostic["certificateChain"].find(
    //           (c) => c.certificate.id === certId
    //         )["certificate"];
    //         console.log("certDetail", certDetail);
    //         const fData = Uint8Array.from(certDetail["base64Encoded"]);
    //         const certFile = new File([fData], `${sig["signedBy"]}.cer`, {
    //           type: "application/pkix-cert",
    //         });
    //         return {
    //           signer: sig["signedBy"],
    //           time: sig["signingTime"],
    //           format: sig["signatureFormat"],
    //           indication: sig["indication"],
    //           signatureCoverage: sig["signatureScope"][0]["scope"],
    //           valid: sigDiagnostic["structuralValidation"].valid,
    //           orgId: certDetail["organizationIdentifier"],
    //           orgName: certDetail["organizationName"],
    //           fiscalCode: certDetail["subjectSerialNumber"],
    //           certFile: certFile,
    //           validationDate: "",
    //           caName: "",
    //           certExp: "",
    //           // keyType: `${certDetail['publicKeyEncryptionAlgo']} ${certDetail['publicKeySize']}`,
    //         };
    //       }
    //     );
    //   }
    //   return [];
    // },
  },
  methods: {
    async uploadFile() {
      try {
        const formData = new FormData();
        formData.append("file", this.file);

        const res = await axios.post(
          `${KUrls.BASE_REST_URL}/public/validate/filesEU`,
          formData,
          {
            onUploadProgress: (progr) => {
              this.updateProgressBar(progr.loaded, progr.total);
            },
          }
        );
        this.validation = res.data;
      } catch (e) {
        console.error(e);
      } finally {
        this.progressVal = 0;
      }
    },
    updateProgressBar(currVal, total) {
      this.progressVal = (currVal / total) * 100;
    },
    getSigColor(sigStatus) {
      var color = "";
      switch (sigStatus) {
        case "TOTAL_PASSED": {
          color = "is-success";
          break;
        }
        case "INDETERMINATE": {
          color = "is-warning";
          break;
        }
        case "TOTAL_FAILED": {
          color = "is-danger";
          break;
        }
      }
      return color;
    },
    downloadCertificate(certFile) {
      FileSaver.saveAs(certFile);
    },
  },
};
</script>

<style></style>
